import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import Avatar from '../Common/Avatar';

const StyledDiv = styled.div`
  float: right;
`;

export default function ProfileHeader({ auth }) {
  const { profile = {}, authenticated = false } = auth;
  const { email, avatar } = profile;

  function toProfile() {
    if (!authenticated && window.self !== window.top) {
      window.open('/profile', '_blank').focus();
    } else {
      window.location = '/profile';
    }
  }

  return <>
    <StyledDiv>
      {authenticated ?
        <Button
          shape='circle'
          type='text'
          title='Open profile page'
          onClick={toProfile}
          icon={<Avatar src={avatar} email={email} />}
        ></Button> :
        <Button
          type='link'
          title='Login to your account'
          icon={<UserOutlined />}
          onClick={toProfile}
        >Login</Button>
      }
    </StyledDiv>
  </>;
}

ProfileHeader.propTypes = {
  auth: PropTypes.object.isRequired,
};
