import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { usStates } from '../../helpers/common';
const { Option } = Select;

export default function SelectState({ value, onChange, placeholder, size = 'medium' }) {
  return (
    <Select
      allowClear
      showSearch
      placeholder={placeholder}
      style={{ minWidth: 110 }}
      size={size}
      value={value}
      onChange={onChange}
      optionFilterProp='key'
    >
      {usStates.map(r => <Option key={`${r.code}-${r.state}`} value={r.code}>{r.state}</Option>)}
    </Select>
  );
}

SelectState.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  size: PropTypes.string,
};
