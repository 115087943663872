import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, notification } from 'antd';
import styled from 'styled-components';
import Mixpanel from '../../analytics/mixpanel';

import apiProfile from '../../api/apiProfile';

const { Text } = Typography;

const StyledText = styled(Text)`
    padding-left: 20px;
    padding-right: 20px;
`;

export default function EmailAddress({ value, verified }) {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  async function onRequest() {
    setLoading(true);

    try {
      await apiProfile.reuqestEmailVerification();
      notification.success({ message: 'Please check your inbox' });
      setSent(true);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }

    Mixpanel.track('Request email confirmation');
  }

  return <>
    {value}
    {verified ?
      <StyledText type='success'>verified</StyledText>
      :
      <>
        <StyledText type='warning'>unverified</StyledText>
        {!sent && <Button loading={loading} onClick={onRequest}>Request Email Verification</Button>}
      </>
    }
  </>;
}

EmailAddress.propTypes = {
  value: PropTypes.string.isRequired,
  verified: PropTypes.bool,
};
