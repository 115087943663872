import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

export default function SelectNaics({ value, onChange, size, placeholder = '326211, 3262, 32', notFoundContent = 'Enter NAICS codes', style }) {
  return (
    <Select
      allowClear
      autoFocus
      showSearch
      maxTagCount={1}
      mode='tags'
      tokenSeparators={[',', '\n']}
      style={style}
      size={size}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      optionFilterProp='children'
      notFoundContent={notFoundContent}
    >
    </Select>
  );
}

SelectNaics.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  notFoundContent: PropTypes.string,
  style: PropTypes.object,
};
