import apiClient from './apiClient';

export default {
  fetchPlaces: (params) => apiClient.get('/places/', { params }),
  fetchCompanies: (params) => apiClient.get('/companies/', { params }),
  fetchCompaniesIndustrySelections: () => apiClient.get('/selections/companies/industry/'),
  fetchPlacesEstimate: (params) => apiClient.get('/estimate/places', { params }),
  fetchPlacesOptions: (column, params) => apiClient.get(`/options/places/${column}`, { params }),
  fetchCompaniesEstimate: (params) => apiClient.get('/estimate/companies', { params }),
  fetchCompaniesOptions: (column, params) => apiClient.get(`/options/companies/${column}`, { params }),
  exportPlaces: (params) => apiClient.get('/places/', { responseType: 'blob', params }),
  exportCompanies: (params) => apiClient.get('/companies/', { responseType: 'blob', params }),
  fetchPlace: (id) => apiClient.get(`/places/${id}/`),
};
