import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

export default function Login({ location, auth }) {
  const { search } = location;
  const { redirect = '/' } = queryString.parse(search);

  localStorage.setItem('redirect', redirect);

  auth.login();

  return <h5>Loading...</h5>;
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
