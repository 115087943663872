import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

export default function Signup({ auth, history }) {
  const { location } = history;
  const { search } = location;
  const { referrer, redirect = '/' } = queryString.parse(search);

  localStorage.setItem('redirect', redirect);

  if (referrer) {
    localStorage.setItem('referrer', referrer);
  }

  auth.signup();

  return <h5>Loading...</h5>;
}

Signup.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
