import React from 'react';
import PropTypes from 'prop-types';
import { Table, Empty } from 'antd';

import BusinessCard from './Business/BusinessCard';

const notFoundContent = <>
  <Empty description={<span>No results matching your filters. Please check your search terms and try again.</span>}>
    <br/><br/>
    Commonly used queries:
    <br/><br/>
    <a href="?limit=10&state=California&sic=806200">Medical & Surgical Hospitals, California, USA</a><br/>
    <br/><br/><br/><br/><br/><br/>
  </Empty>
</>;

export default function BusinessesTable({ places = [], total, pageSize, currentPage, onPagination, loading, onSelect, onSearchParamsChange }) {
  const columns = [{
    title: '',
    dataIndex: 'display_name',
    key: 'display_name',
    render: (_, record) => <BusinessCard value={record} key={record.display_name} onSearchParamsChange={onSearchParamsChange} />,
  }];

  return (
    <Table
      rowKey='_id'
      locale={{ emptyText: notFoundContent }}
      scroll={{ y: '70vh' }}
      showHeader={false}
      loading={loading}
      columns={columns}
      dataSource={places.map(r => { r.key = r.os_id; return r; })}
      pagination={{
        pageSize,
        current: currentPage,
        total: total ? total : pageSize + 1,
        showSizeChanger: false,
        position: ['bottomRight']
      }}
      onRow={(record, rowIndex) => {
        return {
          onClick: () => onSelect(rowIndex),
        };
      }}
      onChange={onPagination}
    />
  );
}

BusinessesTable.propTypes = {
  places: PropTypes.array.isRequired,
  total: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPagination: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onSearchParamsChange: PropTypes.func.isRequired,
};
