import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'antd';
import styled from 'styled-components';

const PriceLabel = styled.span`
  color: #C41230;
  font-size: 20px;
`;

const PriceDetailLabel = styled.span`
  font-size: 14px;
`;

const PriceFeaturesLabel = styled.span`
  font-size: 12px;
`;

export default function PricingCards({ value, unitName = 'item' }) {
  const prices = value || [];

  return (
    <Row gutter={16}>
      {prices.map((price, i) => (
        <Col span={8} key={`tire-${i+1}`}>
          <Card>
            <PriceDetailLabel>$<PriceLabel>{Number(price.unit_amount_decimal.toFixed(4))}</PriceLabel>/{unitName}</PriceDetailLabel><br/>
            {i === 0 ? (
              <PriceFeaturesLabel>Before {price.up_to.toLocaleString()}</PriceFeaturesLabel>
            ) : (
              price.up_to ? <PriceFeaturesLabel>
                Next {prices[i-1].up_to.toLocaleString()} - {price.up_to.toLocaleString()}
              </PriceFeaturesLabel> : <PriceFeaturesLabel>For the rest</PriceFeaturesLabel>
            )}
          </Card>
        </Col>
      ))}
    </Row>
  );
}

PricingCards.propTypes = {
  value: PropTypes.array.isRequired,
  unitName: PropTypes.string,
  cardStyle: PropTypes.object,
};
