import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const { Option } = Select;

export default function ResultsTypeSelect({ value, onChange, loading, style, bordered }) {
  return (
    <Select disabled={loading} defaultValue={value} style={style} onChange={onChange} bordered={bordered}>
      <Option value='csv'>CSV</Option>
      <Option value='xlsx'>XLSX</Option>
      <Option value='json'>JSON</Option>
      <Option value='parquet'>PARQUET</Option>
    </Select>
  );
}

ResultsTypeSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  style: PropTypes.object,
  bordered: PropTypes.bool,
};
