import React from 'react';
import PropTypes from 'prop-types';
import { Table, Empty } from 'antd';

import PlaceCard from './Place/PlaceCard';

const notFoundContent = <>
  <Empty description={<span>No results matching your filters. Please check your search terms and try again.</span>}>
    <br/><br/>
    Commonly used queries:
    <br/><br/>
    <a href="?cc=US&city=Palo Alto&limit=10&state=California&type=gift shop">Gift shops in Palo Alto, USA</a><br/>
    <a href="?cc=US&limit=10&state=New York&type=restaurant&type=bar">Restaurants and bars in New York state, USA</a><br/>
    <a href="?cc=US&city=san francisco&limit=10&state=california&type=real estate agency">Real estate agencies in San Francisco, USA</a><br/>
    <a href="?cc=CA&city=toronto&limit=10&type=clinic&type=doctor&type=therapists">Clinics, doctors and therapists in Toronto, CA</a><br/>
    <a href="?cc=GB&city=london&city=edinburgh&limit=10&type=gym">Gyms in London and Edinburgh, GB</a><br/>
    <br/><br/><br/><br/><br/><br/>
  </Empty>
</>;

export default function PlacesTable({ places = [], total, pageSize, currentPage, onPagination, loading, onSelect, onSearchParamsChange }) {
  const columns = [{
    title: '',
    dataIndex: 'display_name',
    key: 'display_name',
    render: (_, record) => <PlaceCard value={record} key={record.display_name} onSearchParamsChange={onSearchParamsChange} />,
  }];

  return (
    <Table
      rowKey='os_id'
      locale={{ emptyText: notFoundContent }}
      scroll={{ y: '70vh' }}
      showHeader={false}
      loading={loading}
      columns={columns}
      dataSource={places.map(r => { r.key = r.id; return r; })}
      pagination={{
        pageSize,
        current: currentPage,
        total: total ? total : pageSize + 1,
        showSizeChanger: false,
        position: ['bottomRight']
      }}
      onRow={(record, rowIndex) => {
        return {
          onClick: () => onSelect(rowIndex),
        };
      }}
      onChange={onPagination}
    />
  );
}

PlacesTable.propTypes = {
  places: PropTypes.array.isRequired,
  total: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPagination: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onSearchParamsChange: PropTypes.func.isRequired,
};
