import React from 'react';
import styled from 'styled-components';

import AppLogo from '../../assets/app/logo.png';

const StyledDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
  width: 100%;
	padding: 15px 0 15px 0;
`;

const StyledLogo = styled.img`
	width: 160px;
`;

export default function Header() {

  return <>
    <StyledDiv>
      <StyledLogo src={AppLogo} alt='targetron logo' />
    </StyledDiv>
  </>;
}
