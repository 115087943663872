import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { notification, message, Input, Card, Form, InputNumber, Button } from 'antd';

import apiPayment from '../../../../api/apiPayment';

const StyledInput = styled(Input)`
  width: 200px;
`;

const StyledAddressInput = styled(Input)`
  width: 400px;
  background-color: #f5fcff;
`;

const { Meta } = Card;

export default function CryptoPayment({ match }) {
  const { paymentId } = match.params;

  const [paymentDetails, setPaymentDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const { payment_status, pay_address, actually_paid, price_amount, price_currency, pay_amount, pay_currency } = paymentDetails;
  const payCurrency = pay_currency ? pay_currency.toUpperCase() : '';
  const priceCurrency = price_currency ? price_currency.toUpperCase() : '';
  const description = <>Please send <strong>{pay_amount}</strong> {payCurrency} (<strong>exact amount</strong>) to the deposit address displayed below. The deposit will be credited to your account balance.</>;
  const isPaid = actually_paid > 0;

  useEffect(() => {
    if (paymentId) {
      fetchData();

      setInterval(() => {
        fetchData(false);
      }, 30000);
    }
  }, [paymentId]);

  async function fetchData(showLoading = true) {
    if (showLoading) setLoading(true);
    try {
      const data = await apiPayment.getCryptoPayment(paymentId);
      setPaymentDetails(data);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      if (showLoading) setLoading(false);
    }
  }

  function onCopy(selector, copyText) {
    var element = document.querySelector(selector);
    element.select();
    document.execCommand('copy');

    message.success(`${copyText} is copied to your clipboard`);
  }

  return (
    <Card
      title={`Payment #${paymentId} [${payment_status}]`}
      extra={<Button loading={loading} onClick={fetchData} type='default'>Refresh</Button>}
      style={{ width: 450 }}
    >
      <Meta description={isPaid ? `You successfully deposited ${actually_paid} ${payCurrency}. Navigate to the profile page to see your account balance (it might take up to 10 minutes to confirm your transaction).` : description} />
      <br/>
      <Form name='basic' layout='vertical'>
        <Form.Item label={`${payCurrency} amount`}>
          <StyledInput
            readOnly
            className='pay-amount'
            value={pay_amount}
            onClick={() => onCopy('.pay-amount', `${payCurrency} amount`)}
            disabled={loading}
          />
        </Form.Item>
        <Form.Item label={`${priceCurrency} amount`}>
          <StyledInput
            readOnly
            className='price-amount'
            value={price_amount}
            onClick={() => onCopy('.price-amount', `${priceCurrency} amount`)}
            disabled={loading}
          />
        </Form.Item>
        <Form.Item label={`${payCurrency} address`}>
          <StyledAddressInput
            readOnly
            className='pay-address'
            value={pay_address}
            onClick={() => onCopy('.pay-address', `${payCurrency} address`)}
            disabled={loading}
          />
        </Form.Item>
        {isPaid &&
          <Form.Item label={`${payCurrency} actually paid`}>
            <InputNumber
              readOnly
              style={{ width: 250 }}
              className='actually-paid'
              value={actually_paid}
              disabled={loading}
            />
          </Form.Item>
        }
      </Form>
    </Card>
  );
}

CryptoPayment.propTypes = {
  match: PropTypes.object.isRequired,
};
