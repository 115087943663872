import { flag, countries } from 'country-emoji';
import config from 'react-global-configuration';

export const countryCodes = Object.keys(countries);

export const usStates = [
  { state: 'Alabama', code: 'AL' },
  { state: 'Alaska', code: 'AK' },
  { state: 'Arizona', code: 'AZ' },
  { state: 'Arkansas', code: 'AR' },
  { state: 'California', code: 'CA' },
  { state: 'Colorado', code: 'CO' },
  { state: 'Connecticut', code: 'CT' },
  { state: 'Delaware', code: 'DE' },
  { state: 'District of Columbia', code: 'DC' },
  { state: 'Florida', code: 'FL' },
  { state: 'Georgia', code: 'GA' },
  { state: 'Hawaii', code: 'HI' },
  { state: 'Idaho', code: 'ID' },
  { state: 'Illinois', code: 'IL' },
  { state: 'Indiana', code: 'IN' },
  { state: 'Iowa', code: 'IA' },
  { state: 'Kansas', code: 'KS' },
  { state: 'Kentucky', code: 'KY' },
  { state: 'Louisiana', code: 'LA' },
  { state: 'Maine', code: 'ME' },
  { state: 'Maryland', code: 'MD' },
  { state: 'Massachusetts', code: 'MA' },
  { state: 'Michigan', code: 'MI' },
  { state: 'Minnesota', code: 'MN' },
  { state: 'Mississippi', code: 'MS' },
  { state: 'Missouri', code: 'MO' },
  { state: 'Montana', code: 'MT' },
  { state: 'Nebraska', code: 'NE' },
  { state: 'Nevada', code: 'NV' },
  { state: 'New Hampshire', code: 'NH' },
  { state: 'New Jersey', code: 'NJ' },
  { state: 'New Mexico', code: 'NM' },
  { state: 'New York', code: 'NY' },
  { state: 'North Carolina', code: 'NC' },
  { state: 'North Dakota', code: 'ND' },
  { state: 'Ohio', code: 'OH' },
  { state: 'Oklahoma', code: 'OK' },
  { state: 'Oregon', code: 'OR' },
  { state: 'Pennsylvania', code: 'PA' },
  { state: 'Rhode Island', code: 'RI' },
  { state: 'South Carolina', code: 'SC' },
  { state: 'South Dakota', code: 'SD' },
  { state: 'Tennessee', code: 'TN' },
  { state: 'Texas', code: 'TX' },
  { state: 'Utah', code: 'UT' },
  { state: 'Vermont', code: 'VT' },
  { state: 'Virginia', code: 'VA' },
  { state: 'Washington', code: 'WA' },
  { state: 'West Virginia', code: 'WV' },
  { state: 'Wisconsin', code: 'WI' },
  { state: 'Wyoming', code: 'WY' }
];

export function removeEmptyValues(obj){
  for (var k in obj) {
    if (obj[k] === null || obj[k] === '') {
      delete obj[k];
    }
  }
}

export function codeToFlag(countryCode) {
  return flag(countryCode);
}

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function guessOntology(column) {
  if (column === 'email') {
    return 'EMAIL';
  }

  if (column === 'gender') {
    return 'GENFER';
  }

  return 'TEXT';
}

export function getCompany() {
  const domainParts = window.location.hostname.split('.');
  const fullName = domainParts.slice(1, -1).join();
  return fullName ? fullName : 'tar' + 'get' + 'ron';
}

export function getCompanyConfig() {
  return config.get('companies')[getCompany().charAt(0)];
}

export function getCompanyHomePage() {
  const domainParts =  window.location.hostname.split('.');
  const mainDomain = domainParts.slice(1).join('.');
  return `https://${mainDomain}`;
}

export function removeParams(url, startsWithKey) {
  let urlObj = new URL(url);
  let params = urlObj.searchParams;

  for (let key of params.keys()) {
    if (key.startsWith(startsWithKey)) {
      params.delete(key);
    }
  }

  return urlObj.toString();
}

export function removeKeys(obj, startsWithKey) {
  for (let key in obj) {
    if (key.startsWith(startsWithKey)) {
      delete obj[key];
    }
  }
  return obj;
}
