import React from 'react';
import { Tooltip, Badge } from 'antd';
import PropTypes from 'prop-types';

import { showConfirm } from './ReasonsToAddCredits';
import PremiumFeatureIcon from './PremiumFeatureIcon';

export default function PremiumFeatureTooltip({ isPaidUser, children }) {
  function onShowReasonsToAddCredits() {
    if (!isPaidUser) showConfirm(true);
  }

  return <Tooltip onClick={onShowReasonsToAddCredits} title={isPaidUser ? '' : <>
    Upgrade to use this feature <PremiumFeatureIcon />
    <br/><br/>
    Enhance your data analytics capabilities by adding credits to your account. This enables access to a broader range of features, offering deeper, more comprehensive insights and a richer understanding of your data landscape.
  </>}>
    <Badge count={isPaidUser ? null : <>&nbsp;&nbsp;&nbsp;<PremiumFeatureIcon />&nbsp;</>}>
      {children}
    </Badge>
  </Tooltip>;
}

PremiumFeatureTooltip.propTypes = {
  isPaidUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  children: PropTypes.node.isRequired,
};
