export const O = 'out' + 'scr' + 'aper';
export const T = 'tar' + 'get' + 'ron';

export default {
  auth0ClientID: 'iAlx5e3dspTk5yRzIRROsqKK0iDOTEwh',
  apiDomain: '23.145.40.138:5001',
  mixpanelToken: '',
  companies: {
    o: {
      stripePKey: 'pk_test_51J0D3pCzx9P8yVtSHLgKKdtbcg5mkTjIdvEsLXkO9MQJgdt5Mrgnuvemv0FstmF0fdfwlNpDdPiS3KsML88LGSd200E4ez1kny',
      auth0Domain: `${O}.auth0.com`,
      auth0ClientID: 'iAlx5e3dspTk5yRzIRROsqKK0iDOTEwh',
      auth0PayloadKey: `https://app.${O}.com`,
      colors: {
        main: '#031940'
      },
      payPalClientId: 'AZTFoCJUBouxkynuKgSapwxt4ZX3SYiEwSncvc4eA9s_f95YQwV6awypMBpk3Ys4S6323rNA5MAVL3Xz',
    },
    t: {
      stripePKey: 'pk_test_JCFUkK9NGKtAKaW9RJp4kLWm',
      auth0Domain: `${T}.us.auth0.com`,
      auth0CustomDomain: `auth.${T}.com`,
      auth0ClientID: 'Km8rLr81biRxwjo4dXrRynm7AxBV86o2',
      auth0PayloadKey: `https://app.${T}.com`,
      colors: {
        main: '#1B6F7E'
      },
      payPalClientId: 'AcQe27VojDpK8ccuBa4NcY3r7ARS23zoJnn96M72Yy7fyYxNaUsgEuNnmJ-AxaVxLmfHHTZ0eFlUqUnl',
    }
  },
  hcaptchaSiteKey: 'c006edf3-2e6e-46a8-a3d4-b473077f84c2',
};
