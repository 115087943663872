import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, FeatureGroup, Marker, Popup, TileLayer, ZoomControl } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import PlaceTitle from '../Place/PlaceTitle';
import { arraysAreEqual } from '../../../utils/helper';

// fix img not found
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
let DefaultIcon = L.icon({ iconUrl: icon, shadowUrl: iconShadow });
L.Marker.prototype.options.icon = DefaultIcon;

const DEFAULT_ZOOM = 6;
function Map({ places = [], geoFilters, onGeoFiltersChange, selectedPlace = 0, description }) {
  const [map, setMap] = useState(null);

  const navigated = places.length > 0;
  const selectedPlaceValue = places[selectedPlace];
  const center = navigated ? [selectedPlaceValue.latitude, selectedPlaceValue.longitude] : [40.7055537,	-74.0134436];

  let editableFGref = null;

  useEffect(() => {
    if (map) {
      map.setView(center, DEFAULT_ZOOM);
    }

  }, [places]);

  useEffect(() => {
    if (map) {
      map.setView(center, DEFAULT_ZOOM);
    }

  }, [selectedPlace]);

  useEffect(() => {
    if (!geoFilters || geoFilters.length < 1) {
      removeAllLayers();
    }

  }, [geoFilters]);

  function removeAllLayers() {
    if (editableFGref) {
      const drawnItems = editableFGref._layers;

      Object.keys(drawnItems).forEach(layerid => {
        editableFGref.removeLayer(drawnItems[layerid]);
      });
    }
  }

  function onSetActive(place) {
    console.log(place);
  }

  function metersToRadian(meters){
    const earthRadiusInmeters = 6371000;
    return meters / earthRadiusInmeters;
  }

  function onFeatureGroupReady(reactFGref) {
    if (reactFGref) {
      editableFGref = reactFGref;
    }
  }

  function layerToFilter(layer) {
    let result = {};
    const { _mRadius, _leaflet_id, _latlng } = layer;

    if (_mRadius) {
      const { lat, lng } = _latlng;
      result = {
        type: 'Feature',
        properties: {},
        geometry: { type: 'Circle', coordinates: [lng, lat], radius: metersToRadian(_mRadius) }
      };
    } else {
      result = layer.toGeoJSON();
    }

    result.properties.leaflet_id = _leaflet_id;
    return result;
  }

  function handleCreated({ layer }) {
    const filter = layerToFilter(layer);

    if (geoFilters) {
      geoFilters.push(filter);
    } else {
      geoFilters = [filter];
    }

    onGeoFiltersChange(geoFilters);
  }

  function handleEdited({ layers }) {
    layers.eachLayer((layer) => {
      const { _leaflet_id } = layer;
      const previousFilterId = getFilterIndexById(_leaflet_id);

      geoFilters[previousFilterId] = layerToFilter(layer);
    });

    onGeoFiltersChange(geoFilters);
  }

  function handleDeleted({ layers }) {
    const indexesToDelete = [];

    layers.eachLayer((layer) => {
      const { _leaflet_id } = layer;
      indexesToDelete.push(getFilterIndexById(_leaflet_id));
    });

    indexesToDelete.sort(function(a,b){ return b - a; });

    for (let i = indexesToDelete.length -1; i >= 0; i--) {
      geoFilters.splice(indexesToDelete[i], 1);
    }

    onGeoFiltersChange(geoFilters);
  }

  function getFilterIndexById(leafletId) {
    for (let i = 0; i < geoFilters.length; i++) {
      if (geoFilters[i].properties.leaflet_id === leafletId) {
        return i;
      }
    }

    return -1;
  }

  return (
    <MapContainer
      center={center}
      zoom={DEFAULT_ZOOM}
      zoomControl={false}
      whenCreated={setMap}
      style={{ height: '70vh' }}
    >
      <ZoomControl position='topright'/>
      <TileLayer
        attribution={description}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {places && places.map(place =>
        <Marker
          key={place.os_id}
          position={[
            place ? place.latitude : 40.7055537,
            place? place.longitude : -74.0134436
          ]} onClick={() => onSetActive(place)}>
          <Popup>
            <PlaceTitle key={place.id} value={place}/>
          </Popup>
        </Marker>
      )}

      <FeatureGroup ref={(reactFGref) => onFeatureGroupReady(reactFGref)}>
        <EditControl
          position='topright'
          onEdited={handleEdited}
          onCreated={handleCreated}
          onDeleted={handleDeleted}
          draw={{ polyline: false, marker: false, circlemarker: false, rectangle: false }}
        />
      </FeatureGroup>
    </MapContainer>
  );
}

Map.propTypes = {
  places: PropTypes.array.isRequired,
  geoFilters: PropTypes.array,
  onGeoFiltersChange: PropTypes.func.isRequired,
  selectedPlace: PropTypes.number,
  description: PropTypes.string,
};

export default React.memo(Map, (prevProps, nextProps) => {
  return (
    prevProps.selectedPlace === nextProps.selectedPlace &&
    arraysAreEqual(prevProps.places, nextProps.places) &&
    arraysAreEqual(prevProps.geoFilters, nextProps.geoFilters)
  );
});
