import React from 'react';
import PropTypes from 'prop-types';
import { Form, Tag } from 'antd';

export default function GeoFiltersItems({ value }) {
  return <>{value &&
    <Form.Item>
      <Tag>{value.length} area(s)</Tag>
    </Form.Item>
  }</>;
}

GeoFiltersItems.propTypes = {
  value: PropTypes.array,
};
