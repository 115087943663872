import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Layout } from 'antd';
import styled from 'styled-components';

import { getCompany, getCompanyHomePage, capitalize } from './helpers/common';
import configureStore from './redux/store';
import Auth from './auth/Auth';
import Logout from './auth/Logout';
import Login from './auth/Login';
import Signup from './auth/Signup';
import ReferrerRedirect from './auth/ReferrerRedirect';
import Callback from './auth/Callback';
import PrivateRoute from './utils/PrivateRoute';
import Sider from './Components/Navigation/Sider';
import history from './utils/history';
import Places from './Components/Places/Places';
import Businesses from './Components/Businesses/Businesses';
import Profile from './Components/Profile/Profile';
import Invoices from './Components/Invoices/Invoices';
import BillingInformation from './Components/Profile/BillingInformation';
import ApiDocs from './Components/ApiDocs/ApiDocs';
import Checkouts from './Components/Checkouts/Checkouts';
import CheckoutResults from './Components/Common/CheckoutResults';
import CheckoutHistory from './Components/Common/CheckoutHistory';
import MakeCryptoPayment from './Components/Profile/AddCredits/Crypto/MakeCryptoPayment';
import CryptoPayment from './Components/Profile/AddCredits/Crypto/CryptoPayment';
import Header from './Components/Navigation/Header';

const StyledDiv = styled.div`
    background: #fff;
    padding: 30px;
    min-height: 100%;
`;

const auth = new Auth();

const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
};
const PLAIN_PAGES = new Set([
  '/checkout-history', '/local-businesses', '/businesses', '/places', '/companies', '/checkout-results'
]);

const homePage = getCompanyHomePage();
const company = capitalize(getCompany());

function App() {
  const { location } = history;
  const { pathname } = location;
  const { authenticated, profile } = auth;
  const { ie } = profile;

  const showSider = !PLAIN_PAGES.has(pathname);

  useEffect(() => {
    if (localStorage.getItem('isLoggedIn') === '1') {
      auth.renewSession();
    }

    const intervalId = setInterval(() => {
      if (localStorage.getItem('isLoggedIn') === '1') {
        auth.renewSession();
      }
    }, 1000 * 60 * 20);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Provider store={configureStore()}>
      <Router history={history}>
        {ie && <>God Mode: {ie}</>}
        {showSider ?
          <Layout style={{ minHeight: '100vh' }}>
            <Sider auth={auth} history={history}/>
            <Layout>
              <Layout.Content style={{ padding: '10px 10px' }}>
                <StyledDiv>
                  <Switch>
                    <Route exact path='/'>{authenticated ? <Redirect to='/profile'/> : <Redirect to='/login'/>}</Route>
                    <PrivateRoute exact path='/profile' component={Profile} auth={auth} authForwarded={auth} location={location} />
                    <PrivateRoute exact path='/profile/crypto-payment' component={MakeCryptoPayment} auth={auth} location={location} />
                    <PrivateRoute exact path='/profile/crypto-payment/:paymentId' component={CryptoPayment} auth={auth} location={location} />
                    <PrivateRoute exact path='/exports' component={Checkouts} auth={auth} authForwarded={auth} location={location} />
                    <PrivateRoute exact path='/billing-info' component={BillingInformation} auth={auth} authForwarded={auth} location={location} />
                    <PrivateRoute exact path='/invoices' component={Invoices} auth={auth} location={location}/>
                    <Route exact path='/api-docs' render={(props) => <ApiDocs {...props} auth={auth}/>} />

                    <Route exact path='/login' render={(props) => <Login auth={auth} {...props} />}/>
                    <Route exact path='/signup' render={(props) => <Signup auth={auth} history={history} {...props} />}/>
                    <Route exact path='/logout' render={(props) => <Logout auth={auth} {...props} />}/>
                    <Route path='/callback' render={(props) => {
                      handleAuthentication(props);
                      return <Callback {...props}/>;
                    }}/>
                  </Switch>
                </StyledDiv>
              </Layout.Content>
              <Layout.Footer style={{ textAlign: 'center', background: '#fff' }}>
                <a target='_blank' rel='noreferrer' href={homePage}>{`Copyright 2020-${new Date().getFullYear()} ${company} ©`}</a>
              </Layout.Footer>
            </Layout>
          </Layout> :
          <Header />
        }
        <Route exact path='/checkout-history' component={CheckoutHistory} />
        <Route exact path='/local-businesses' render={(props) => <Places {...props} auth={auth}/>} />
        <Route exact path='/businesses' render={(props) => <Businesses {...props} auth={auth}/>} />
        <Route exact path='/places' render={(props) => <Places {...props} auth={auth}/>} />
        <Route exact path='/companies' render={(props) => <Businesses {...props} auth={auth}/>} />
        <Route exact path='/refer' render={(props) => <ReferrerRedirect {...props} history={history} />} />

        <Route exact path='/checkout-results' component={CheckoutResults} />
      </Router>
    </Provider>
  );
}

export default App;
