import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { regions } from '../../shared/data/regions';

const { Option } = Select;

export default function SelectCountry({ value, onChange, size }) {
  return (
    <Select
      allowClear
      showSearch
      placeholder='Country'
      style={{ minWidth: '120px' }}
      size={size}
      value={value}
      onChange={onChange}
      optionFilterProp='key'
    >
      {regions.map(r => <Option key={`${r.name}-${r.value}`} value={r.value}>{r.name}</Option>)}
    </Select>
  );
}

SelectCountry.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string
};
