import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, List } from 'antd';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';

import HidedValue from './HidedValue';

export default function Contacts({ value = [] }) {
  const contactsAmount = value ? value.length : 0;

  return <>
    <h4>Employees Contacts{contactsAmount && ` (${contactsAmount})`}:</h4>

    <List
      size='small'
      itemLayout='horizontal'
      rowKey={r => r.email}
      dataSource={value}
      pagination={{
        pageSize: 3,
        hideOnSinglePage: true,
        showSizeChanger: false,
        size: 'small'
      }}
      renderItem={el => (
        <List.Item
          actions={[
            el.email ? <><MailOutlined /> Email: <HidedValue value={el.email}/></> : null,
            el.phone ? <><PhoneOutlined /> Phone: <HidedValue value={el.phone}/> {el.phone_type && <>({el.phone_type})</>}</> : null,
          ].filter(Boolean)}
        >
          <List.Item.Meta
            avatar={<Avatar size='small' style={{ backgroundColor: '#f56a00' }}>{el.name && el.name.charAt(0)}</Avatar>}
            title={<><HidedValue value={el.name}/></>}
            description={<>{el.title && el.title}</>}
          />
        </List.Item>
      )}
    />
  </>;
}

Contacts.propTypes = {
  value: PropTypes.array.isRequired,
};
