import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Form, InputNumber, Button, Popconfirm, Tooltip, Drawer, Collapse, notification } from 'antd';
import { UserOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import styled from 'styled-components';

import Mixpanel from '../../analytics/mixpanel';
import ResultsTypeSelect from './ResultsTypeSelect';
import PricingCards from '../Common/PricingCards';
import { downloadDemo, fetchPrices, fetchProfile, checkout } from '../../redux/actions/profileActions';

const DEFAULT_QUANTITY = 10000;
const MIN_QUANTITY = 1;

const PriceLabel = styled.span`
  color: #58C560;
  font-size: 28px;
  font-weight: 800;
`;

const PriceDetailLabel = styled.span`
  font-size: 20px;
  padding-right: 8px
`;

const StyledSpan = styled.span`
  padding-inline: 8px
`;

const StyledButtonText = styled.button`
  color: #262626;
  text-decoration: underline;
`;

const StyledButton = styled.button`
  color: #262626;
  font-size: 12px;
  text-decoration: underline;
`;

const StyledQuantityInput = styled.div`
  display: flex;
  align-items: center;
`;

export default function CheckoutModal({ visible, onCancel, searchParams, totalResults = 0, productName = 'Places', units = 'rows', auth, prelaodProfile = true }) {
  const { skip: defaultSkip = 0 } = searchParams;

  const loading = useSelector(state => state.profileReducer.loading);
  const prices = useSelector(state => state.profileReducer.prices[productName]) || [];
  const currentUsage = parseInt(localStorage.getItem(`usage-${productName}`) || 0);
  const dispatch = useDispatch();

  const [pricesOpened, setPricesOpened] = useState(false);
  const [type, setType] = useState('csv');
  const [skip, setSkip] = useState(0);
  const [quantity, setQuantity] = useState(DEFAULT_QUANTITY);

  const previousPrice = calculatePrice ? calculatePrice(currentUsage) : 0;
  const price = calculatePrice(quantity + currentUsage) - previousPrice;

  const isAuthenticated = !!localStorage.getItem('isLoggedIn');
  const emailVerified = localStorage.getItem('emailVerified') == 'true';

  useEffect(() => {
    dispatch(fetchPrices(productName));
    if (isAuthenticated && prelaodProfile) dispatch(fetchProfile());
  }, []);

  useEffect(() => {
    if (visible) {
      if (!prelaodProfile) {
        dispatch(fetchProfile());
      }

      const newSkip = totalResults > 1 ? defaultSkip : 0;
      setQuantity(Math.min(DEFAULT_QUANTITY, totalResults - newSkip || DEFAULT_QUANTITY));
      setSkip(newSkip);
    }

  }, [visible]);

  function onDownloadDemo() {
    dispatch(downloadDemo(searchParams, quantity, type, productName));
  }

  function onSetQuantity(v) {
    setQuantity(v);
    if (skip > 0 && totalResults - v < skip) {
      setSkip(totalResults - v);
    }
  }

  function onSetSkip(v) {
    setSkip(v);
    if (quantity + v > totalResults) {
      setQuantity(totalResults - v);
    }
  }

  async function hanldeOnCheckout() {
    await onCheckout();
  }

  async function onCheckout(verified=emailVerified) {
    if (isAuthenticated) {
      if (!verified) {
        if (auth) {
          await auth.renewSession(() => {
            if (localStorage.getItem('emailVerified') == 'true') {
              onCheckout(true);
            }
          });
        } else {
          notification.warning({ message: 'Please check your inbox and verify your email' });
        }
        return;
      }
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      if (skip > 0) queryParams.set('skip', skip.toString());
      const redirect = `${window.location.pathname}?${queryParams.toString()}&exportType=${type}&exportQuantity=${quantity}&exportTotalResults=${totalResults}&exportProductName=${productName}&exportUnits=${units}&exportPrice=${price}`;
      const link = `/signup?redirect=${encodeURIComponent(redirect)}`;
      if (window.self !== window.top) {
        window.open(link, '_blank').focus();
      } else {
        window.location = link;
      }
    }

    Mixpanel.track('Checkout', { type, quantity, price, productName, units });
    await dispatch(checkout({...searchParams, skip }, quantity, totalResults, type, productName, units, price));
  }

  function onLogin() {
    const { pathname, search = '' } = window.location;
    const link = `/login?redirect=${encodeURIComponent(pathname + search)}`;

    if (window.self !== window.top) {
      window.open(link, '_blank').focus();
    } else {
      window.location = link;
    }

    Mixpanel.track('Login (from checkout)');
  }

  function calculatePrice(usage) {
    let price = 0;
    let previousUpTo = 0;

    for (let i = 0; i < prices.length; i++) {
      const { up_to, unit_amount_decimal } = prices[i];

      if (up_to && usage > up_to) {
        price += (up_to - previousUpTo) * unit_amount_decimal;
        previousUpTo = up_to;
      } else {
        return price + (usage - previousUpTo) * unit_amount_decimal;
      }
    }

    return price;
  }

  return (
    <Modal
      destroyOnClose
      title='Exporting Data'
      okButtonProps={{ loading }}
      visible={visible}
      onOk={hanldeOnCheckout}
      onCancel={onCancel}
      footer={[
        <StyledSpan key='login'>
          {(!isAuthenticated) && <Tooltip title='Login to your account to be able to use your credits'>
            <Button
              ghost
              type='primary'
              className='login'
              icon={<UserOutlined />}
              onClick={onLogin}
              title='Login to your account'
            >Login</Button>
          </Tooltip>}
        </StyledSpan>,
        <Tooltip
          key='demo'
          mouseEnterDelay={3}
          title='Download the demo file to see the results example'>
          <Button
            ghost
            type='primary'
            className='demo'
            loading={loading}
            onClick={onDownloadDemo}
            title='Download demo results'>Demo</Button>
        </Tooltip>,
        <StyledSpan key='download'>
          {(isAuthenticated) ?
            <Popconfirm
              key='download'
              title={`Are you sure to checkout ${quantity.toLocaleString()} items?`}
              onConfirm={hanldeOnCheckout}
            >
              <Button
                key='download'
                type='primary'
                className='checkout-authenticated'
                loading={loading}
                title='Checkout and download data'>{`Checkout ${quantity.toLocaleString()} Rows`}</Button>
            </Popconfirm>
            :
            <Button
              key='download'
              type='primary'
              className='checkout'
              loading={loading}
              onClick={hanldeOnCheckout}
              title='Checkout and download results'>{`Checkout ${quantity.toLocaleString()} Rows`}</Button>
          }
        </StyledSpan>
      ]}
    >
      <Form style={{ fontSize: 24 }}>
        <Form.Item label='Type' tooltip='File format. You will be able to change it later.'>
          <ResultsTypeSelect loading={loading} value={type} onChange={setType} style={{ width: 200 }} />
        </Form.Item>
        <Form.Item label='Quantity' tooltip={`Amount of the results to export. The maximum amount accordingly to your filters is ${totalResults ? totalResults.toLocaleString() : 'undefined'}.`}>
          <StyledQuantityInput>
            <InputNumber
              autoFocus
              style={{ width: 180, marginRight: 10 }}
              min={MIN_QUANTITY}
              max={type === 'xlsx' ? Math.min(totalResults, 1000000) : totalResults}
              step={1000}
              value={quantity}
              disabled={loading}
              onChange={(v) => onSetQuantity(v || MIN_QUANTITY)}
            />
            {totalResults && <>
              from&nbsp;
              <StyledButtonText
                type='button'
                className='link-button'
                onClick={() => onSetQuantity(totalResults)}
              ><strong><em>{totalResults.toLocaleString()}</em></strong></StyledButtonText>
              &nbsp;available
            </>}
          </StyledQuantityInput>
          <div style={{ marginTop: 5 }}>
            {totalResults && totalResults !== quantity && <>
              <StyledButton
                type='button'
                className='link-button'
                onClick={() => onSetQuantity(totalResults)}
              >All {totalResults.toLocaleString()}</StyledButton>
            </>}
          </div>
        </Form.Item>
        <Collapse ghost style={{ marginTop: -30 }}>
          <Collapse.Panel forceRender header='Options' key='options'>
            <Form.Item label='Skip' tooltip='Amount of the results to skip'>
              <InputNumber
                autoFocus
                style={{ width: 180 }}
                max={totalResults-1}
                step={100}
                value={skip}
                disabled={loading}
                onChange={(v) => onSetSkip(v || 0)}
              />
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
        <div>
          <strong>
            Total Price <QuestionCircleTwoTone style={{ fontSize: 18 }} title='Pricing' onClick={() => setPricesOpened(true)}/> :
          </strong> <PriceDetailLabel><PriceLabel>${price.toFixed(2).toLocaleString()}</PriceLabel></PriceDetailLabel>
        </div>
      </Form>
      <Drawer
        width={590}
        title='Pricing'
        onClose={() => setPricesOpened(false)}
        visible={pricesOpened}
      >
        <PricingCards value={prices} unitName='biz' />
      </Drawer>
    </Modal>
  );
}

CheckoutModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  totalResults: PropTypes.number,
  searchParams: PropTypes.object,
  productName: PropTypes.string,
  units: PropTypes.string,
  auth: PropTypes.object,
  prelaodProfile: PropTypes.bool,
};
