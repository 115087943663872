import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popconfirm, Button, Row, Col, Tooltip } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import ChangeEmailModal from './ChangeEmailModal';

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

export default function ProfileDangerousActions({ onPasswordChangeRequest, onRemove, onLogout, onEmailChanged, loading, passwordChangeRequested, authProvider }) {
  const [isChangeEmailModalVisible, setIsChangeEmailModalVisible] = useState(false);
  const internalProvider = authProvider ? authProvider === 'auth0' : true;
  const notChangeableTooltip = internalProvider ? '' : `Account created with ${authProvider}`;

  function handleOnEmailChanged(email) {
    setIsChangeEmailModalVisible(null);
    onEmailChanged(email);
  }

  return <>
    <Row>
      <Col span={24} style={{ textAlign: 'right' }}>
        <Popconfirm title='Are you sure you want to remove your account with all data?' onConfirm={onRemove}>
          <StyledButton ghost type='danger' title='Remove Account' loading={loading}>
            Remove Account
          </StyledButton>
        </Popconfirm>
        <Tooltip title={notChangeableTooltip}>
          <Popconfirm
            title='Are you sure you want to change your password?'
            disabled={!internalProvider}
            onConfirm={onPasswordChangeRequest}
          >
            <StyledButton
              ghost
              type='primary'
              title='Change Password'
              loading={loading}
              disabled={passwordChangeRequested || !internalProvider}
            >Change Password</StyledButton>
          </Popconfirm>
        </Tooltip>
        <StyledButton
          ghost
          type='primary'
          title='Change Email'
          loading={loading}
          disabled={isChangeEmailModalVisible === null}
          onClick={() => setIsChangeEmailModalVisible(true)}
        >Change Email</StyledButton>
        <Popconfirm title='Are you sure you want to log out?' onConfirm={onLogout}>
          <StyledButton
            ghost
            title='Log Out'
            type='primary'
            icon={<LogoutOutlined />}
          >Log Out</StyledButton>
        </Popconfirm>
      </Col>
    </Row>
    <ChangeEmailModal
      visible={isChangeEmailModalVisible}
      onCancel={() => setIsChangeEmailModalVisible(false)}
      onChanged={handleOnEmailChanged}
      authProvider={authProvider}
      internalProvider={internalProvider}
    />
  </>;
}

ProfileDangerousActions.propTypes = {
  onPasswordChangeRequest: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onEmailChanged: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  passwordChangeRequested: PropTypes.bool,
  authProvider: PropTypes.string,
};
