import React from 'react';
import PropTypes from 'prop-types';
import { Rate } from 'antd';
import styled from 'styled-components';

const StyledRate = styled(Rate)`
  font-size: 20px;
`;

const Reviews = styled.span`
  font-size: 16px;
`;

const StyledRatingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default function Rating({ value }) {
  const { rating, reviews } = value;

  return (
    <StyledRatingWrapper>
      {reviews && <Reviews style={{ fontSize: 16, fontWeight: '500' }}>{rating + ' '}</Reviews>}
      <StyledRate allowHalf disabled value={rating}/>
      {reviews && <Reviews> ({reviews})</Reviews>}
    </StyledRatingWrapper>
  );
}

Rating.propTypes = {
  value: PropTypes.object.isRequired,
};
