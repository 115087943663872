import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import CheckoutModal from './CheckoutModal';

export default function ExportButton({ totalResults, loading, disabled, searchParams, title = 'Export Results', type = 'primary', productName = 'Places', units = 'rows', auth }) {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Button
        title='Export the results to a file'
        size='middle'
        className='export'
        type={type}
        loading={loading}
        disabled={disabled}
        onClick={() => setModalVisible(!modalVisible)}
        icon={<DownloadOutlined />}
      >{title}</Button>

      <CheckoutModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        auth={auth}
        totalResults={totalResults}
        searchParams={searchParams}
        productName={productName}
        units={units}
      />
    </>
  );
}

ExportButton.propTypes = {
  totalResults: PropTypes.number,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  searchParams: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
  productName: PropTypes.string,
  units: PropTypes.string,
  auth: PropTypes.object,
};
