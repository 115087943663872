import React from 'react';
import PropTypes from 'prop-types';

import BusinessesTable from './BusinessesTable';

export default function SearchResults({ places, total, skip, pageSize, onPagination, loading, onSelect, onSearchParamsChange }) {
  const currentPage = Math.ceil((parseInt(skip | 0) + 1) / pageSize);

  return (
    <BusinessesTable
      loading={loading}
      places={places}
      total={total}
      currentPage={currentPage}
      pageSize={pageSize}
      onPagination={onPagination}
      onSelect={onSelect}
      onSearchParamsChange={onSearchParamsChange}
    />
  );
}

SearchResults.propTypes = {
  places: PropTypes.array.isRequired,
  total: PropTypes.number,
  skip: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPagination: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onSearchParamsChange: PropTypes.func.isRequired,
};
