import React, { useState } from 'react';
import { Drawer } from 'antd';

export default function PaymentInfo() {
  const [learnMore, setLearnMore] = useState(false);

  return (
    <>
      <a onClick={() => setLearnMore(true)}>Why do we need it?</a>
      <Drawer
        title='Payments Info'
        placement='right'
        closable={false}
        onClose={() => setLearnMore(false)}
        visible={learnMore}
      >
        <p>
          All payments are backed by <a href='https://stripe.com' target='_blank' rel='noopener noreferrer'>Stripe</a>. Stripe is a PCI Service Provider Level 1 which is the highest grade of payment processing security. You can rest assured that your information is safe and secure.
        </p>
        <p>
          Upon completion of your billing period, you will only receive an invoice for the usage exceeding the Free Tier.
        </p>
        <p>
          If you still want to try it without any obligation, contact us and let us know you better.
        </p>
      </Drawer>
    </>
  );
}

export const warningMessage = <>
  Please add billing information, or add more credits before using the API.&#160;
</>;
