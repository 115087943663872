import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Input, Button, Tooltip, Modal, Popconfirm, notification } from 'antd';
import Mixpanel from '../../analytics/mixpanel';

import { getCompanyHomePage } from '../../helpers/common';
import apiProfile from '../../api/apiProfile';
import { fetchProfile } from '../../redux/actions/profileActions';

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

export default function PromotionCode({ existingCouponName }) {
  const [promotionCode, setPromotionCode] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const withoutCoupon = !existingCouponName;

  async function apply() {
    setLoading(true);
    try {
      const data = await apiProfile.applyPromotionCode(promotionCode);

      Mixpanel.track('Apply promo code', { promotionCode });

      notification.success({ message: `Coupon "${data}" is applied` });

      if (data.toLowerCase().includes('appsumo'))
        Modal.success({
          title: 'Hello, Sumo-lings!',
          content: <>
            You are ready to go using the services! Before you begin you also might want to read
            <a target='_blank'
              rel='noopener noreferrer'
              href={`${getCompanyHomePage()}/appsumo-faq`}> this article</a> about the most common questions of AppSumo customers.
          </>,
        });

      setPromotionCode('');

      dispatch(fetchProfile());
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  async function handleKeyDown({ key }) {
    if (key === 'Enter' && promotionCode.length >= 5) {
      await apply();
    }
  }

  return <>
    <Input
      disabled={loading}
      style={{ width: 300 }}
      value={promotionCode}
      onKeyDown={handleKeyDown}
      onChange={({ target: { value } }) => setPromotionCode(value)}
    />
    {promotionCode.length >= 5 && (
      <Popconfirm
        title={<>
          <Tooltip title='Currently, we support only one special offer per account.'>
            Are you sure to <strong className='warning'>replace</strong> your existing offer &quot;<strong>{existingCouponName}</strong>&quot;?
          </Tooltip>
        </>}
        onConfirm={apply}
        disabled={withoutCoupon}
      >
        <StyledButton
          title='Apply Promotion Code'
          onClick={withoutCoupon && apply}
          loading={loading}
        >Apply</StyledButton>
      </Popconfirm>
    )}
  </>;
}

PromotionCode.propTypes = {
  existingCouponName: PropTypes.string,
};
