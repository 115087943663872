import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

export default function Tags({ value = [], onClick }) {
  return <>
    {value && value.map(tag =>
      <Tag key={tag} onClick={() => onClick(tag)}>{tag}</Tag>
    )}
  </>;
}

Tags.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array]),
  onClick: PropTypes.func.isRequired,
};
