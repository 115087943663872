import apiClient from './apiClient';

export default {
  fetchPaymentSource: () => apiClient.get('/profile/payment-source'),
  createCheckoutSession: (payload) => apiClient.post('/create-checkout-session', payload),
  checkoutWithBalance: (payload) => apiClient.post('/checkout-with-balance', payload),
  getCheckoutHistory: (productName) => apiClient.get(`/checkout-history/${productName}`),
  getCheckouts: () => apiClient.get('/checkouts'),
  createAPIToken: () => apiClient.put('/profile/api-token'),
  revokeAPIToken: () => apiClient.delete('/profile/api-token'),
  updateGodMode: (email) => apiClient.put('/profile/god-mode', { email } ),
  applyPromotionCode: (code) => apiClient.put('/profile/apply-promotion-code', { code }),
  featureRequest: (body) => apiClient.post('/profile/feature-request', { body }),
  requestEmailConfirmationCode: (email) => apiClient.put('/profile/change-email/request-code', { email }),
  changeEmail: (email) => apiClient.put('/profile/change-email', { email }),
  reuqestEmailVerification: () => apiClient.put('/profile/request-email-verification'),
};
