import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { getCompanyHomePage } from '../helpers/common';

const HOME_PAGE = getCompanyHomePage();

export default function ReferrerRedirect({ history }) {
  const { location } = history;
  const { search } = location;
  const { referrer, redirect = '' } = queryString.parse(search);

  if (referrer) {
    localStorage.setItem('referrer', referrer);
  }

  window.location.replace(redirect ? `${HOME_PAGE}/${redirect}` : HOME_PAGE);

  return <h5>Loading...</h5>;
}

ReferrerRedirect.propTypes = {
  history: PropTypes.object.isRequired,
};
