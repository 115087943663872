import {
  START_REQUEST,
  STOP_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PRICES_SUCCESS,
  FETCH_BILLINGINFO_SUCCESS,
} from '../actions/profileActions';

export default (state = { profile: {}, billingInfo: {}, prices: {} }, action) => {
  switch (action.type) {
  case START_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case STOP_REQUEST: {
    return {
      ...state,
      loading: false,
    };
  }
  case FETCH_PROFILE_SUCCESS: {
    return {
      ...state,
      profile: action.profile,
    };
  }
  case FETCH_PRICES_SUCCESS: {
    return {
      ...state,
      prices: action.prices,
    };
  }
  case FETCH_BILLINGINFO_SUCCESS: {
    return {
      ...state,
      billingInfo: action.billingInfo,
    };
  }
  default:
    return state;
  }
};
