import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Badge } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { handleOnCopy } from '../../../utils/helper';

const customPlaceTitleStyles = { fontWeight: 'bold' };

const statusesMap = {
  operational: 'processing',
  closed_permanently: 'error',
  closed_temporarily: 'warning',
  Operational: 'processing',
  'Closed permanently': 'error',
  'Closed temporarily': 'warning'
};

export default function PlaceTitle({ value, onExport }) {
  const { display_name, business_status = '', business_status_history, verified, owner_title } = value;

  function onCopy(e) {
    if (!handleOnCopy(e) && onExport) {
      onExport();
    }
  }

  return (
    <h2 onCopy={onCopy} style={customPlaceTitleStyles}>
      {display_name}
      {verified &&
        <Tooltip title={`Claimed by owner (${owner_title})`}>
          <CheckCircleOutlined style={{ marginLeft: 10 }} />
        </Tooltip>
      }
      <Tooltip
        title={<>
          Status: <strong><em>{business_status && business_status.replace('_', ' ')}</em></strong>
          {business_status_history && <>
            <br/><br/>
            history:
            <br/>
            {business_status_history.map((el, i) => <div key={`${el}-${i}`}>{el.replace('_', ' ')}<br/></div>)}
          </>}
        </>}>
        <Badge style={{ marginLeft: 10 }} status={statusesMap[business_status]} title='Active' />
      </Tooltip>
    </h2>
  );
}

PlaceTitle.propTypes = {
  value: PropTypes.object.isRequired,
  onExport: PropTypes.func,
};
