import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function InfoTooltip({ title }) {
  return (
    <Tooltip title={title}>
      <InfoCircleOutlined style={{ paddingLeft: 12 }}/>
    </Tooltip>
  );
}

InfoTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
