import React from 'react';
import { notification } from 'antd';
import { isEmpty } from 'lodash';
import { loadStripe } from '@stripe/stripe-js';
import config from 'react-global-configuration';

import { getCompanyConfig, removeParams } from '../../helpers/common';
import { setPaidUser, isRecentlyUsedNTimes } from '../../utils/utils';
import Mixpanel from '../../analytics/mixpanel';
import history from '../../utils/history';
import apiClient from '../../api/apiClient';
import apiProfile from '../../api/apiProfile';
import apiPayment from '../../api/apiPayment';

export const START_REQUEST = 'START_REQUEST';
export const STOP_REQUEST = 'STOP_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PRICES_SUCCESS = 'FETCH_PRICES_SUCCESS';
export const FETCH_BILLINGINFO_SUCCESS = 'FETCH_BILLINGINFO_SUCCESS';
export const FETCH_INTEGRATIONS_SUCCESS = 'FETCH_INTEGRATIONS_SUCCESS';

const COMPANY_CONFIG = getCompanyConfig();
const products = ['Places', 'Companies And Executives'];

export const startRequest = () => ({ type: START_REQUEST });
export const stopRequest = () => ({ type: STOP_REQUEST });

export const fetchProfile = () => async (dispatch) => {
  dispatch(startRequest());
  try {
    const data = await apiClient.get('/profile');
    dispatch({ profile: data, type: FETCH_PROFILE_SUCCESS });
    const { upcoming_invoice = {}, account_status } = data;
    const { products_lines = [] } = upcoming_invoice;

    setPaidUser(account_status === 'valid');

    for (const p of products) {
      localStorage.removeItem(`usage-${p}`);
    }
    for (const { product_name, quantity } of products_lines) {
      localStorage.setItem(`usage-${product_name}`, quantity);
    }
    return data;
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
  return {};
};

export const removeProfile = () => async (dispatch) => {
  dispatch(startRequest());
  try {
    const data = await apiClient.delete('/profile');

    notification.success({ message: data, duration: 6 });

    setTimeout(() => history.push('/logout'), 5000);
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const makePaymentNMI = (amount, secondaryTab = false) => async (dispatch) => {
  try {
    dispatch(startRequest());

    const origin = 'https://app.targetron.com';// window.location.origin;
    var successUrl = `${origin}/profile?deposited=true`;
    if (secondaryTab) successUrl += '&secondary_tab=true';

    await window.CollectCheckout.redirectToCheckout({
      type: 'sale',
      lineItems: [
        { lineItemType: 'purchase', sku: '001', quantity: amount },
      ],
      successUrl,
      cancelUrl: origin,
      receipt: {
        showReceipt: true,
        sendToCustomer: true,
      },
      collectShippingInfo: false,
      useKount: false,
      paymentMethods: [
        { type: 'creditCard', use3DSecure: false },
        { type: 'googlePay', use3DSecure: false },
      ],
      fields: [],
    }).then((error) => {
      if (error) notification.error({ message: error });
    });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const makePaymentStripe = (amount, secondaryTab = false) => async (dispatch) => {
  try {
    dispatch(startRequest());

    const { sessionId } = await apiClient.post('/create_payment_checkout_session', { amount, secondaryTab });

    var stripe = window.Stripe(COMPANY_CONFIG.stripePKey);

    stripe.redirectToCheckout({ sessionId }).then((result) => {
      notification.error({ message: result.error.message });
    });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const updateGodMode = (email, callback = null) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const data = await apiProfile.updateGodMode(email);
    notification.success({ message: data });
    if (callback) callback();
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const downloadDemo = (searchParams, quantity, type, productName) => async (dispatch) => {
  try {
    dispatch(startRequest());

    const textSearchParams = window.btoa(JSON.stringify(searchParams));
    const url = `${window.location.protocol}//${config.get('apiDomain')}/download-checkout-results?demo=true&fileType=${type}&searchParams=${textSearchParams}&productName=${productName}`;

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `places_demo_${quantity}.${type}`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Download demo', searchParams);
};

export const checkout = (searchParams, quantity, total, type, productName, units = 'rows', price = null, extra = {}) => async (dispatch) => {
  const successUrl = `${window.location.origin}/checkout-results?fileType=${type}&quantity=${quantity}&units=${units}`;

  try {
    dispatch(startRequest());

    const { status, success_url, message, amount } = await apiProfile.checkoutWithBalance({
      search_params: searchParams,
      quantity,
      total,
      file_type: type,
      product_name: productName,
      success_url: successUrl,
      current_url: removeParams(window.location.href, 'export'),
      extra,
    });

    if (status === 'success') {
      if (success_url) {
        window.location = success_url;
      } else if (message) {
        notification.success({ message, duration: 10 });
        return true;
      }
    } else {
      if (status === 'low_balance') {
        notification.error({
          message: <>Insufficient account balance. Please navigate to <a target='_blank' onClick={() => window.open('/profile?secondary_tab=true')}>the profile page</a> and add credits.</>,
          duration: 10
        });
      } else if (status === 'unauthorized') {
        const link = `/signup?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`;
        if (window.self !== window.top) {
          window.open(link, '_blank').focus();
        } else {
          window.location = link;
        }
      } else if (status === 'checkout' && amount) {
        if (isRecentlyUsedNTimes('np')) {
          notification.error({
            message: <>Insufficient account balance. Please navigate to <a target='_blank' onClick={() => window.open('/profile?secondary_tab=true')}>the profile page</a> and add credits.</>,
            duration: 10
          });
        } else {
          await window.CollectJS.configure({
            variant: 'lightbox',
            callback: (response) => {
              onFinishCheckout(response, searchParams, quantity, total, type, productName, units, Math.min(amount, price), dispatch);
            }
          });
          window.CollectJS.startPaymentRequest();
        }
      } else if (status === 'open_invoices') {
        notification.error({
          message: <>Please close <a target='_blank' href="invoices">past due invoices</a>.</>,
          duration: 10
        });
      } else {
        // dispatch(checkoutWithCard(searchParams, quantity, total, type, productName, successUrl, extra));
        notification.error({
          message: <>Insufficient account balance. Please navigate to <a target='_blank' onClick={() => window.open('/profile?secondary_tab=true')}>the profile page</a> and add credits.</>,
          duration: 10
        });
      }
    }
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

async function onFinishCheckout({ token }, searchParams, quantity, total, type, productName, units, price, dispatch) {
  dispatch(startRequest());
  await nmiCharge(token, price);
  await new Promise(r => setTimeout(r, 4000));
  dispatch(checkout(searchParams, quantity, total, type, productName, units, price));
}

export async function nmiCharge(token, amount, onSuccess=null, onError=null) {
  try {
    const { response, responsetext } = await apiPayment.nmiCharge(token, amount);
    if (response === '1') {
      notification.success({ message: 'Payment Successful' });
      if (onSuccess) onSuccess();
    } else {
      notification.error({ message: `Payment Unsuccessful: "${responsetext}"` });
      if (onError) onError();
    }
  } catch (error) {
    notification.error({ message: error.message });
    if (onError) onError();
  }
}

export const checkoutWithCard = (searchParams, quantity, total, type, productName, successUrl) => async (dispatch) => {
  try {
    dispatch(startRequest());

    const { id } = await apiProfile.createCheckoutSession({
      productName,
      quantity: quantity,
      successUrl,
      cancelUrl: window.location.href,
      payload: {
        search_params: searchParams,
        quantity,
        total,
        file_type: type,
        product_name: productName
      }
    });

    const stripePromise = loadStripe(COMPANY_CONFIG.stripePKey);
    const stripe = await stripePromise;
    const result = await stripe.redirectToCheckout({ sessionId: id });

    if (result.error) {
      notification.error({ message: result.error.message });
    }
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const fetchPrices = (productName) => async (dispatch, getState) => {
  const prices = getState().profileReducer.prices;
  if (isEmpty(prices[productName])) {
    try {
      dispatch(startRequest());
      prices[productName] = await apiClient.get(`/prices/${productName}`);
      dispatch({ prices, type: FETCH_PRICES_SUCCESS });
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      dispatch(stopRequest());
    }
  }
};

export const stopSubscription = (subscription_id) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.post('/stop-customer-subscription', { subscription_id });

    notification.success({ message: data, description: 'The invoice will be finalized within a hour. You will receive it by email.', duration: 10 });
    dispatch(fetchProfile());
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Stop subscription');
};

export const voidSubscription = (subscription_id) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.post('/void-customer-subscription', { subscription_id });

    notification.success({ message: data });
    dispatch(fetchProfile());
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Clear subscription');
};

export const createAPIToken = () => async (dispatch) => {
  dispatch(startRequest());
  try {
    const data = await apiProfile.createAPIToken();
    notification.success({ message: data });
    dispatch(fetchProfile());
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Create API token');
};

export const revokeAPIToken = () => async (dispatch) => {
  dispatch(startRequest());
  try {
    const data = await apiProfile.revokeAPIToken();
    notification.success({ message: data });
    dispatch(fetchProfile());
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Revoke API token');
};

export const fetchBillingInfo = () => async (dispatch) => {
  dispatch(startRequest());
  try {
    const data = await apiClient.get('/profile/billing-info');
    dispatch({ billingInfo: data, type: FETCH_BILLINGINFO_SUCCESS });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const updateBillingInfo = (payload) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const data = await apiClient.put('/profile/billing-info', payload);
    notification.success({ message: data });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Update billing info');
};
