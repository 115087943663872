import React from 'react';
import { Col, Row, Modal, Image } from 'antd';
import styled from 'styled-components';
import PremiumFeatureIcon from './PremiumFeatureIcon';

const StyledDiv = styled.div`
  min-height: 465px;
`;

export function showConfirm(premiumFeature = false) {
  Modal.confirm({
    icon: null,
    width: 890,
    style: { top: 30 },
    closable: true,
    okText: 'Proceed to Payment',
    onOk: () => {
      if (premiumFeature) window.open('/profile?secondary_tab=true', '_blank', 'noopener,noreferrer');
    },
    cancelButtonProps: { style: { display: 'none' } },
    title: <>Reasons to Boost Your Account with Credits{premiumFeature && <>&nbsp;&nbsp;&nbsp;<PremiumFeatureIcon /></>}</>,
    content: <StyledDiv>
      <br/><br/>
      <Row gutter={16}>
        <Col span={3}>
          <Image height={75} src='https://outscraper.com/wp-content/uploads/2023/12/Professional-Services.png' preview={false} />
        </Col>
        <Col span={19}>
          <h3>Enhanced Professional Services</h3>
          By adding credits, you directly contribute to the enhancement and expansion of our most valued features, ensuring a continually evolving and high-quality user experience.
        </Col>
      </Row>
      <br/><br/>

      <Row gutter={16}>
        <Col span={3}>
          <Image height={75} src='https://outscraper.com/wp-content/uploads/2023/12/investment_10434273.png' preview={false} />
        </Col>
        <Col span={19}>
          <h3>Your Contribution: Keeping Our Prices Competitive</h3>
          Your credit additions are vital in maintaining competitive pricing and preventing misuse of the free tier. Your contribution helps maintain a fair and sustainable pricing model.
        </Col>
      </Row>
      <br/><br/>

      <Row gutter={16}>
        <Col span={3}>
          <Image height={75} src='https://outscraper.com/wp-content/uploads/2023/12/strategy_10426299.png' preview={false} />
        </Col>
        <Col span={19}>
          <h3>Unlock More Records, Unleash More Insights</h3>
          Enhance your data analytics capabilities by adding credits to your account. This enables access to a broader range of features, offering deeper, more comprehensive insights and a richer understanding of your data landscape.
        </Col>
      </Row>
    </StyledDiv>,
  });
}
